import React from 'react';

export const ProductCardRenderer = (props) => {
   return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
         <div>TODO</div>
      </>
   );
};
export default ProductCardRenderer;
